import React, { useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { PAGE_PATHS } from "./page-paths";
import { serverName, resourcesUrl } from "../config";
import { useAuth } from "../utils/hooks/useAuth";
import { UserOnboardingStats } from "./Home/VLEStatsFragment";
import usePromise from "../utils/hooks/usePromise";
import { services } from "../services";
import { VLE } from "@unicef/shared-resources/models/db";
import Loader from "../assets/animated-images/loader";
import { useState } from "react";

import DataTable from "react-data-table-component";
import logo from "../../src/assets/logo.png";
import csc from "../../src/assets/csc.png";
import unicefLogo from "../../src/assets/UNICEF-logo.png";
import UserIcon from "../assets/user.svg";

type User = {
  id: number;
  name: string;
  gender: string;
  onboardingStatus: string;
  courseCompleted: boolean;
  email?: string;
  mobileNumber?: string;
  createdAt: string;
  updatedAt: string;
};
type GenderWiseDistribution = {
  male?: number;
  female?: number;
  other?: number;
};

function calculateStatistics(userOnboardingStats: any, filter: any) {
  const { fromYear, fromMonth, toYear, toMonth } = filter;

  console.log({ fromYear, fromMonth, toYear, toMonth, userOnboardingStats });

  const filteredStats = userOnboardingStats.filter((stat: any) => {
    const statDate = new Date(stat.year, stat.month - 1);
    const fromDate = new Date(fromYear, fromMonth - 1);
    const toDate = new Date(toYear, toMonth - 1);
    return statDate >= fromDate && statDate <= toDate;
  });

  const totalOnboarded = filteredStats.reduce(
    (sum: any, stat: any) => sum + stat.onboardingCount,
    0
  );

  const confirmedUsers = filteredStats
    .filter((stat: any) => stat.onboardingStatus === "confirmed")
    .reduce((sum: any, stat: any) => sum + stat.onboardingCount, 0);

  const courseCompletionCount = filteredStats.reduce(
    (sum: any, stat: any) => sum + stat.courseCompletionCount,
    0
  );

  const genderWiseDistribution = filteredStats.reduce((acc: any, stat: any) => {
    const gender = stat.gender || "Unknown";
    acc[gender] = (acc[gender] || 0) + stat.onboardingCount;
    return acc;
  }, {});

  return {
    totalOnboarded,
    confirmedUsers,
    courseCompletionCount,
    genderWiseDistribution,
  };
}

export default function HomePage() {
  const { vle } = useAuth();

  const lastYear = new Date().getFullYear() - 1;
  const currentYear = new Date().getFullYear();

  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");

  const [fromYear, setFromYear] = useState(lastYear);
  const [fromMonth, setFromMonth] = useState(currentMonth);
  const [toYear, setToYear] = useState(currentYear);
  const [toMonth, setToMonth] = useState(currentMonth);

  const [stats, setStats] = useState<{
    totalOnboarded: number;
    confirmedUsers: number;
    courseCompletionCount: number;
    genderWiseDistribution: GenderWiseDistribution;
  }>({
    totalOnboarded: 0,
    confirmedUsers: 0,
    courseCompletionCount: 0,
    genderWiseDistribution: {},
  });
  const [showDropdown, setShowDropdown] = useState(false);
  const [tableData, setTableData] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [filters, setFilters] = useState({
    vleId: -1,
    page: 1,
    pageSize: 10,
    search: "",
    gender: "",
    onboardingStatus: "",
    courseComplete: "",
  });

  console.log("Total rows", totalRows);

  const logout = useCallback(() => {
    document.cookie = "access_token=;domain=" + serverName;
    window.location.reload();
  }, []);

  const { result: userStats, loading: loadingUserStats } = usePromise(
    {
      fn: async () => {
        const vleCscId = vle?.cscId;
        if (vleCscId) {
          const response = await services.requestSvc.get<{
            vle: VLE;
            userOnboardingStats: UserOnboardingStats;
            confirmedUserCount: number;
            genderData: Record<string, number>;
          }>("/get-stats-by-vle?vleCscId=" + vleCscId);

          return {
            vle: response.vle,
            groupedData: response.userOnboardingStats,
          };
        }
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [vle]
  );
  const { result: onboardingLimitInfo } = usePromise(
    {
      fn: async () => {
        if (vle?.id) {
          return services.requestSvc.get<{
            count: number;
            vleOnboardingLimit: number;
            stateOnboardingCount: number;
            stateOnboardingLimit: number;
          }>("/vle-onboarding-count?vleId=" + vle.id);
        }
      },
      runOnMount: { args: [] },
      runOnRerender: { enabled: true, args: [] },
    },
    [vle]
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      console.log("VLE data", vle);
      const params = { ...filters, vleId: vle?.id };
      const response = await services.requestSvc.get<{
        status: string;
        data: Array<{
          id: number;
          name: string;
          gender: string;
          onboardingStatus: string;
          courseCompleted: boolean;
          email?: string;
          mobileNumber?: string;
          createdAt: string;
          updatedAt: string;
        }>;
        pagination?: {
          currentPage: number;
          pageSize: number;
          totalUsersCount: number;
          totalPages: number;
        };
      }>("/users", {
        params,
      });

      if (response?.data && response.pagination) {
        setTableData(response.data);
        setTotalRows(response.pagination.totalUsersCount);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [filters, vle]);

  useEffect(() => {
    if (userStats?.groupedData) {
      console.log("userstats", userStats?.groupedData);

      const filter = {
        fromYear,
        fromMonth,
        toYear,
        toMonth,
      };

      const calculatedStats = calculateStatistics(
        userStats.groupedData,
        filter
      );
      console.log({ calculatedStats });
      setStats(calculatedStats);
    }
  }, [userStats, fromYear, fromMonth, toYear, toMonth]);

  const years = Array.from({ length: 8 }, (_, i) => 2023 + i);
  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];
  useEffect(() => {
    if (vle) fetchData();
  }, [filters, vle, fetchData]);

  const handlePageChange = (page: any) => {
    console.log("New page", page);
    setFilters((prev) => ({ ...prev, page }));
  };

  const handleRowsPerPageChange = (pageSize: any) => {
    setFilters((prev) => ({ ...prev, pageSize, page: 1 }));
  };

  const handleFilterChange = (key: any, value: any) => {
    setFilters((prev) => ({ ...prev, [key]: value, page: 1 }));
  };

  const columns = [
    { name: "Learner ID", selector: (row: any) => row.id, sortable: true },
    { name: "Name", selector: (row: any) => row.name, sortable: true },
    { name: "Email", selector: (row: any) => row.email, sortable: true },
    {
      name: "Mobile Number",
      selector: (row: any) => row.mobileNumber,
      sortable: true,
    },
    { name: "Gender", selector: (row: any) => row.gender, sortable: true },
    {
      name: "Course completed",
      selector: (row: any) => (row.courseCompleted ? "Yes" : "No"),
      sortable: true,
    },
  ];

  return (
    <div className="min-h-screen bg-[#F2F6FC]">
      <header className="bg-white shadow-sm rounded-b-lg px-4 py-3 flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <img className="w-16" src={unicefLogo} alt="unicef" />
          <img className="w-16 h-10" src={logo} alt="logo" />
          <img className="w-16" src={csc} alt="csc" />
        </div>

        <div className="hidden md:flex flex-grow mx-6 items-center justify-between text-sm">
          <div className="flex items-center space-x-6">
            <p className="text-blue-900 font-medium bg-[#EDF2FA] rounded-lg px-4 py-2 text-gray-600">
              VLE Name:{" "}
              <span className="font-semibold">{userStats?.vle?.name}</span>
            </p>
            <p className="text-blue-900 font-medium bg-[#EDF2FA] rounded-lg px-4 py-2 text-gray-600">
              VLE ID:{" "}
              <span className="font-semibold">{userStats?.vle?.cscId}</span>
            </p>
            <p className="text-blue-900 font-medium bg-[#EDF2FA] rounded-lg px-4 py-2 text-gray-600">
              State:{" "}
              <span className="font-semibold">
                {userStats?.vle?.State?.name}
              </span>
            </p>
            <p className="text-blue-900 font-medium bg-[#EDF2FA] rounded-lg px-4 py-2 text-gray-600">
              District:{" "}
              <span className="font-semibold">
                {userStats?.vle?.District?.name}
              </span>
            </p>
          </div>
          <a href={resourcesUrl} target="_blank">
            <button className="rounded-md border hover:bg-blue-700 text-blue px-4 py-2 text-sm font-medium rounded-full">
              View Resources
            </button>
          </a>
        </div>

        <div className="relative">
          <div
            className="flex items-center space-x-2 cursor-pointer"
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span className="bg-gray-300 text-blue-900 font-semibold text-sm w-10 h-10 flex items-center justify-center rounded-full">
              <img src={UserIcon} className="m-2" />
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-blue-900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>

          {showDropdown && (
            <div className="absolute top-14 right-2 bg-white shadow-md rounded-lg w-80 p-4 z-10">
              <div className="md:hidden">
                <p className="flex text-blue-900 font-medium mb-2">
                  <span className="block font-bold">VLE Name:</span>
                  {userStats?.vle?.name}
                </p>
                <p className="flex text-blue-900 font-medium mb-2">
                  <span className="block font-bold">VLE ID:</span>
                  {userStats?.vle?.cscId}
                </p>
                <p className="flex text-blue-900 font-medium mb-2">
                  <span className="block font-bold">State:</span>
                  {userStats?.vle?.State?.name}
                </p>
                <p className="flex text-blue-900 font-medium mb-4">
                  <span className="block font-bold">District:</span>
                  {userStats?.vle?.District?.name}
                </p>
                <a href={resourcesUrl} target="_blank">
                  <button className="w-full rounded-md border hover:bg-blue-700 text-blue px-4 py-2 text-sm font-medium rounded-full">
                    View Resources
                  </button>
                </a>
              </div>

              <button
                onClick={logout}
                className="w-full rounded-md border hover:bg-blue-700 text-blue px-4 py-2 text-sm font-medium rounded-full"
              >
                Logout
              </button>
            </div>
          )}
        </div>
      </header>

      <div className="px-4 max-w-[1600px] mx-auto flex flex-col gap-y-8 mt-8">
        <div className="flex flex-wrap justify-between items-center rounded-lg mb-6">
          <div>
            <h1 className="text-[#2A6EC6] text-xl sm:text-2xl font-bold">
              Welcome!
            </h1>
            <p className="text-gray-500 text-sm">
              Notice: All P2E courses are free of cost
            </p>
          </div>
          <div className="rounded-md flex flex-col gap-2">
            <div className="flex flex-wrap items-center gap-2">
              <div className="flex items-center rounded-lg px-2 py-2 text-center">
                <h3 className="font-medium p-2 bg-[#b1c7e9] w-36 rounded-sm">
                  State limit
                </h3>
                <p className="text-blue-900 font-bold p-2 bg-white rounded-sm">
                  {onboardingLimitInfo?.stateOnboardingLimit}
                </p>
              </div>
              <div className="flex items-center rounded-lg px-2 py-2 text-center">
                <h3 className="font-medium p-2 bg-[#b1c7e9] w-40 rounded-sm">
                  Your maximum limit
                </h3>
                <p className="text-blue-900 font-bold p-2 bg-white rounded-sm">
                  {onboardingLimitInfo?.vleOnboardingLimit}
                </p>
              </div>
              <div className="flex items-center rounded-lg px-2 py-2 text-center">
                <h3 className="font-medium p-2 bg-[#b1c7e9] rounded-sm">
                  Your pending user limit
                </h3>
                <p className="text-blue-900 font-bold p-2 bg-white rounded-sm">
                  {Math.min(
                    Number(onboardingLimitInfo?.stateOnboardingLimit) -
                      Number(onboardingLimitInfo?.stateOnboardingCount),
                    Number(onboardingLimitInfo?.vleOnboardingLimit) -
                      Number(onboardingLimitInfo?.count)
                  )}
                </p>
              </div>
            </div>

            <div className="flex justify-end mt-4">
              <Link to={PAGE_PATHS.SIGNUP} className="w-1/2">
                <button className="btn-primary w-full">Onboard User</button>
              </Link>
            </div>
          </div>
        </div>

        <div className="rounded-lg">
          <div className="flex flex-wrap justify-between items-center mb-4">
            <h3 className="text-blue-900 font-bold text-lg">Statistics</h3>
            <div className="flex flex-wrap space-x-2 sm:space-x-4 items-center">
              <span className="font-semibold">From</span>
              <select
                className="bg-white border border-gray-300 rounded-lg px-4 py-2"
                value={fromMonth}
                onChange={(e) => setFromMonth(e.target.value)}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <select
                className="bg-white border border-gray-300 rounded-lg px-4 py-2"
                value={fromYear}
                onChange={(e) => setFromYear(Number(e.target.value))}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
              <span className="font-semibold">To</span>
              <select
                className="bg-white border border-gray-300 rounded-lg px-4 py-2"
                value={toMonth}
                onChange={(e) => setToMonth(e.target.value)}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </select>
              <select
                className="bg-white border border-gray-300 rounded-lg px-4 py-2"
                value={toYear}
                onChange={(e) => setToYear(Number(e.target.value))}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-12 gap-4">
            <div className="flex flex-col bg-white col-span-1 sm:col-span-3 border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-[#b1c7e9] px-4 py-2 font-semibold text-center">
                No. of users onboarded by you
              </div>
              <div className="flex gap-2">
                <div className="flex border border-gray-300 flex-col text-center p-8 w-1/2">
                  <div className="text-3xl text-[#2A6EC6]">
                    {stats.totalOnboarded}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex bg-white flex-col col-span-1 sm:col-span-3 border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-[#b1c7e9] px-4 py-2 font-semibold text-center">
                No. of users completed the course
              </div>
              <div className="text-center p-8">
                <div className="text-3xl text-[#2A6EC6]">
                  {" "}
                  {stats.courseCompletionCount}
                </div>
              </div>
            </div>

            <div className="col-span-1 sm:col-span-6 bg-white border border-gray-300 rounded-lg overflow-hidden">
              <div className="bg-[#b1c7e9] px-4 py-2 font-semibold text-center">
                Gender wise distribution
              </div>
              <div className="flex gap-2">
                <div className="flex border border-gray-300 flex-col text-center p-8 w-1/3">
                  <div className="text-3xl text-[#2A6EC6]">
                    {stats.genderWiseDistribution.male || 0}
                  </div>
                  <div className="text-lg">Male</div>
                </div>
                <div className="flex flex-col text-center p-8 w-1/3">
                  <div className="text-3xl text-[#2A6EC6]">
                    {stats.genderWiseDistribution.female || 0}
                  </div>
                  <div className="text-lg">Female</div>
                </div>
                <div className="flex flex-col border border-gray-300 text-center p-8 w-1/3">
                  <div className="text-3xl text-[#2A6EC6]">
                    {stats.genderWiseDistribution.other || 0}
                  </div>
                  <div className="text-lg">Others</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between">
          <h3 className="text-blue-900 font-bold text-lg">List</h3>
          <div className="flex flex-wrap space-x-2 sm:space-x-4 ml-2">
            <div className="flex space-x-2">
              <input
                type="text"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
                placeholder="Search"
                className="bg-white border border-gray-300 rounded-lg px-4 py-2"
              />
              <button
                onClick={() => {
                  setFilters((prev) => ({
                    ...prev,
                    search: searchInput,
                    page: 1,
                  }));
                }}
                className="px-4 py-2 bg-blue-500 border border-blue-500  rounded-lg hover:bg-blue-700"
              >
                Search
              </button>
            </div>

            <select
              className="bg-white border border-gray-300 rounded-lg px-4 py-2"
              onChange={(e) => handleFilterChange("gender", e.target.value)}
            >
              <option value="" selected>
                All Genders
              </option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">Other</option>
            </select>

            <select
              className="bg-white border border-gray-300 rounded-lg px-4 py-2"
              onChange={(e) =>
                handleFilterChange("onboardingStatus", e.target.value)
              }
            >
              <option value="" selected>
                All Onboarding Status
              </option>
              <option value="pending">Pending</option>
              <option value="confirmed">Confirmed</option>
            </select>

            <select
              className="bg-white border border-gray-300 rounded-lg px-4 py-2"
              onChange={(e) =>
                handleFilterChange("courseComplete", e.target.value)
              }
            >
              <option value="" selected>
                All Completion Status
              </option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
        </div>

        <div className="">
          {loading ? (
            <Loader style={{ height: "40px" }} />
          ) : (
            <DataTable
              columns={columns}
              data={tableData}
              paginationDefaultPage={filters.page}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              customStyles={{
                table: {
                  style: {
                    borderRadius: "8px 8px 0px 0px",
                    border: "1px solid #B9B9B9",
                  },
                },
                headRow: {
                  style: {
                    background: "#b1c7e9",
                    color: "#3D3D3D",
                    fontWeight: "600",
                    "&:hover": {
                      cursor: "pointer",
                    },
                  },
                },
                headCells: {
                  style: {
                    border: "1px solid #B9B9B9",
                  },
                },
                cells: {
                  style: {
                    borderLeft: "1px solid #B9B9B9",
                    borderRight: "1px solid #B9B9B9",
                    borderBottom: "1px solid #B9B9B9",
                  },
                },
                rows: {
                  style: {
                    backgroundColor: "#fff",
                    cursor: "default",
                    color: "#656363",
                    fontSize: "14px",
                    "&:hover": {
                      cursor: "default",
                      backgroundColor: "#E9E9E9",
                    },
                  },
                },
                pagination: {
                  style: {
                    backgroundColor: "rgba(233, 233, 233)",
                    borderRadius: "0.375rem",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    borderLeftWidth: "2px",
                    borderBottomWidth: "2px",
                    borderRightWidth: "2px",
                    borderTopWidth: "0px",
                    borderColor: "rgb(185, 185, 185)",
                    fontWeight: "500",
                    color: "rgb(61, 61, 61)",
                  },
                },
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}
